import {Link, Outlet} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import './App.scss';

function Navigation() {
  return <nav>
    <ul>
      <li>
        <Link to={"/"}>Home</Link>
      </li>
      <li>
        <Link to={"/movies"}>Movies</Link>
      </li>
      <li>
        <Link to={"/movie_night"}>Movie Night</Link>
      </li>
      <li>
        <Link to={"/vinyl"}>Vinyl</Link>
      </li>
    </ul>
  </nav>;
}

function App() {
  return (
    <Row>
      <Col xs={1}>
        <div className={"d-flex"}>
          <h1 className={""}>Media</h1>
        </div>
        <Navigation/>
      </Col>
      <Col>
        <Outlet/>
      </Col>
    </Row>
  );
}

export default App;
