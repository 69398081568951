import {useGetVinylQuery} from "../apis/mediaApi.ts";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {MediaItem, Record} from "../types/media.ts";
import ListRow from "../components/ListRow.tsx";
import Search from "../components/Search.tsx";
import { getFilteredTitle } from "../utils/StringUtils.ts";

function Vinyl() {
  const [vinyl, setVinyl] = useState<Record[]>([]);
  const [filteredVinyl, setFilteredVinyl] = useState<readonly Record[]>([])
  const { data: vinylResponse } = useGetVinylQuery(null);
  useEffect(() => {
    if (vinylResponse) {
      const sortedVinyl = [...vinylResponse];
      sortedVinyl.sort((a: Record, b: Record) => {
        const artistA = getFilteredTitle(a.artist);
        const artistB = getFilteredTitle(b.artist);
        return artistA.localeCompare(artistB);
      });
      setVinyl(sortedVinyl);
      setFilteredVinyl(sortedVinyl);
    }
  }, [vinylResponse]);

  return (
    <>
      <Search mediaList={vinyl} setFilteredList={setFilteredVinyl as Dispatch<SetStateAction<readonly MediaItem[]>>}/>
      {filteredVinyl?.map((vinyl) => {
        return (
          <ListRow media={vinyl} key={vinyl.id}/>
        );
      })}
    </>
  );
}

export default Vinyl;
