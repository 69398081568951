import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import {Provider} from "react-redux";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import App from './routes/App.tsx'
import {store} from "./store.ts";
import Movies from "./routes/Movies.tsx";
import Vinyl from "./routes/Vinyl.tsx";
import MovieNight from "./routes/MovieNight.tsx";
import ErrorPage from "./routes/ErrorPage.tsx";
import './index.scss'

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/movies",
        element: <Movies />
      },
      {
        path: "/vinyl",
        element: <Vinyl />
      },
      {
        path: "/movie_night",
        element: <MovieNight />
      },
    ]
  }
])

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </StrictMode>,
);
