import {useGetMoviesQuery} from "../apis/mediaApi";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {MediaItem, Movie} from "../types/media";
import ListRow from "../components/ListRow";
import Search from "../components/Search";
import { getFilteredTitle } from '../utils/StringUtils';

function Movies() {
  const [movies, setMovies] = useState<Movie[]>([]);
  const [filteredMovies, setFilteredMovies] = useState<readonly Movie[]>([]);
  const { data: movieResponse } = useGetMoviesQuery(null);

  useEffect(() => {
    if (movieResponse?.length) {
      const sortedMovies = [...movieResponse];
      sortedMovies.sort((a: Movie, b: Movie) => {
        const titleA = getFilteredTitle(a.title);
        const titleB = getFilteredTitle(b.title);
        return titleA.localeCompare(titleB);
      });
      setMovies(sortedMovies);
      setFilteredMovies(sortedMovies);
    }
  }, [movieResponse]);

  return (
    <>
      <Search mediaList={movies} setFilteredList={setFilteredMovies as Dispatch<SetStateAction<readonly MediaItem[]>>}/>
      {filteredMovies?.map((movie) => {
        return (
          <ListRow media={movie} key={movie.id}/>
        );
      })}
    </>
  );
}

export default Movies;
