import { Container } from "react-bootstrap";
import {MediaItem, Movie, Record} from "../types/media.ts";
import MediaCard from "./MediaCard.tsx";

const ListRow = ({media}: {media: Movie | Record | MediaItem}) => {
  return (
    <Container>
      <MediaCard media={media}/>
    </Container>
  );
}

export default ListRow;
