import {useEffect, useMemo, useRef} from "react";
import debounce from "lodash.debounce";

export const useDebounce = (callback: () => any) => {
  const ref = useRef<Function>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, 500);
  }, []);
};
