import Fuse from "fuse.js";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction, useState,
} from "react";
import {Button, Col, InputGroup, Row} from "react-bootstrap";
import {useDebounce} from "../hooks/useDebounce.ts";
import {MediaItem} from "../types/media.ts";

type Props = {
  mediaList: readonly MediaItem[];
  setFilteredList: Dispatch<SetStateAction<readonly MediaItem[]>>
};

function Search({mediaList, setFilteredList}: Props) {
  const [updatingFilter, setUpdatingFilter] = useState(false);
  const [filterValue, setFilterValue] = useState<string>("");

  const options = {
    keys:['title', 'artist', 'year', 'label', 'collection_name']
  };
  const fuse = new Fuse(mediaList, options);
  const searchWithFuse = (query: string) => {
    if (!query || !query.length) {
      return mediaList;
    }
    return fuse.search(query).map((result) => result.item)
  };

  const debounceOnChangeHandler = useDebounce(() => {
    setUpdatingFilter(false);
    setFilteredList(searchWithFuse(filterValue || ""));
  });

  const updateFilter = (value: string) => {
    setFilterValue(value);
    setUpdatingFilter(true);
    debounceOnChangeHandler();
  }

  const inputChangedHandler = ({target}: ChangeEvent<HTMLInputElement>) => {
    updateFilter(target.value);
  };

  const clearSearch = () => {
    if (filterValue === "") {
      return;
    }
    updateFilter("");
  }

  return (
    <Row className={"justify-content-center mt-5"}>
      <Col xs={6}>
        <InputGroup className="form-floating mb-3">
          <input type="text" className="form-control" id="query" placeholder="Filter"
                 value={filterValue} onChange={inputChangedHandler}/>
          <label htmlFor="query">Search:</label>
          <Button onClick={clearSearch}>Clear</Button>
        </InputGroup>
      </Col>
      <Col xs={2}>
        {updatingFilter && (
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </Col>
    </Row>
  );
}

export default Search;
