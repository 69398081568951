import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Movie, Record } from "../types/media.ts";

export const mediaApi = createApi({
  reducerPath: "media",
  baseQuery: fetchBaseQuery({ baseUrl: 'https://media.jlcooper.me/api'}),
  endpoints: (build) => ({
    getMovies: build.query<Movie[], null>({
      query: () => '/movies',
    }),

    getMovieNightMovies: build.query<Movie[], null>({
      query: () => '/movies/movie_night',
    }),

    getVinyl: build.query<Record[], null>({
      query: () => '/vinyl',
    }),
  })
});

export const {
  useGetMoviesQuery,
  useGetVinylQuery,
  useGetMovieNightMoviesQuery,
} = mediaApi;
