import {useGetMovieNightMoviesQuery} from "../apis/mediaApi";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {MediaItem, Movie} from "../types/media";
import ListRow from "../components/ListRow";
import Search from "../components/Search";

function MovieNight() {
  const [movies, setMovies] = useState<Movie[]>([]);
  const [filteredMovies, setFilteredMovies] = useState<readonly Movie[]>([]);
  const { data: movieResponse } = useGetMovieNightMoviesQuery(null);
  useEffect(() => {
    if (movieResponse?.length) {
      setMovies(movieResponse);
      setFilteredMovies(movieResponse);
    }
  }, [movieResponse]);

  return (
    <>
      <Search mediaList={movies} setFilteredList={setFilteredMovies as Dispatch<SetStateAction<readonly MediaItem[]>>}/>
      {filteredMovies?.map((movie) => {
        return (
          <ListRow media={movie} key={movie.id}/>
        );
      })}
    </>
  );
}

export default MovieNight;
